import React from "react"
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Center,
  Box,
} from "@chakra-ui/react"
import RowData from "./RowData"

const TableResult = ({ data, baseValue, conversion }) => {
  return (
    <Box my="10">
      <Center>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Code</Th>
                <Th>Name</Th>
                <Th isNumeric>Current Value</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((x) => (
                <RowData
                  conversion={conversion}
                  key={x.code}
                  code={x.code}
                  name={x.name}
                  flag={x.flag}
                  baseValue={baseValue}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
    </Box>
  )
}

export default TableResult
