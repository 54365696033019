import React from "react"
import { Tr, Td, Image } from "@chakra-ui/react"

const RowData = ({ code, name, flag, baseValue, conversion }) => {
  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  let currentValue = conversion
    ? currencyFormat(conversion[code.toLowerCase()] * baseValue)
    : 0

  return (
    <>
      <Tr>
        <Td>
          <Image border="1px" maxW="30px" src={flag} />
        </Td>
        <Td>{code}</Td>
        <Td>{name}</Td>
        <Td isNumeric>{currentValue}</Td>
      </Tr>
    </>
  )
}

export default RowData
