import React from "react"
import { MenuItem, Image } from "@chakra-ui/react/"

const Currency = ({ code, name, flag, onBaseChange }) => {
  return (
    <MenuItem onClick={() => onBaseChange(code)}>
      <Image
        borderRadius="full"
        boxSize="20px"
        minW="20px"
        src={flag}
        alt={flag}
        mr="12px"
      />

      <span>
        {code} - {name}
      </span>
    </MenuItem>
  )
}

export default Currency
