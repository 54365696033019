import React, { useState } from "react"
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  Center,
  Image,
  Input,
  SimpleGrid,
} from "@chakra-ui/react"
import { FaChevronDown, FaSyncAlt } from "react-icons/fa"
import Currency from "./Currency"

const BaseInput = ({ data, base, onBaseChange, onRefresh }) => {
  const [baseValue, setBaseValue] = useState("")

  let currencyName = data.find((x) => x.code === base)
  const handleChange = (event) => {
    setBaseValue(event.target.value)
  }

  return (
    <Center>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<FaChevronDown />}
            leftIcon={
              // <Image
              //   boxSize="40px"
              //   src={currencyName["flag"]}
              //   alt={currencyName["flag"]}
              //   mr="12px"
              // />
              <Image maxW="30px" src={currencyName["flag"]} />
            }
          >
            {base}
          </MenuButton>
          <MenuList>
            {data.map((c) => (
              <Currency
                onBaseChange={onBaseChange}
                key={c.code}
                code={c.code}
                name={c.name}
                country={c.country}
                flag={c.flag}
              />
            ))}
          </MenuList>
        </Menu>
        <Input
          onChange={handleChange}
          placeholder="ex. 109"
          size="md"
          type="number"
        />
        <Button
          onClick={() => onRefresh(baseValue)}
          colorScheme="blue"
          variant="solid"
        >
          Convert
        </Button>
      </SimpleGrid>
    </Center>
  )
}

export default BaseInput
