import React from "react"
import { Center, Text, Icon, Box } from "@chakra-ui/react"
import { RiMoneyCnyBoxLine } from "react-icons/ri"

const Header = () => {
  return (
    <Box mb={10}>
      <Center bg="#495C83" h={{ base: "50px", md: "150px" }} color="white">
        <Icon
          as={RiMoneyCnyBoxLine}
          w={{ base: "10", md: "20" }}
          h={{ base: "10", md: "20" }}
        />
        <Text fontSize={{ base: "20px", md: "50px" }}> Currency Converter</Text>
      </Center>
    </Box>
  )
}

export default Header
