import React from "react"
import Header from "./Header"
import BaseInput from "./BaseInput"
import { getCurrencyData } from "../util/data"
import autoBind from "auto-bind"
import TableResult from "./TableResult"

class App extends React.Component {
  constructor(props) {
    super(props)
    let data = getCurrencyData()

    // cari di localstorage, jika tidak ada setting menjadi usd
    let baseCode = localStorage.getItem("base") || "USD"
    let baseValue = localStorage.getItem("baseValue") || 1

    this.state = {
      data: data,
      base: baseCode,
      baseValue: baseValue,
      conversion: "",
    }
    // data opsi semua currency
    autoBind(this)
  }

  onBaseChange(newBase) {
    this.setState({
      base: newBase,
    })

    // simpan di local storage
    localStorage.setItem("base", newBase)
  }

  onRefresh(baseValue) {
    this.setState({
      baseValue: baseValue,
    })

    // simpan di localstorage
    localStorage.setItem("baseValue", baseValue)

    // dapatkan data konversi terbaru
    fetch(
      `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${this.state.base.toLowerCase()}.json`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          conversion: data[this.state.base.toLowerCase()],
        })
      })
  }

  render() {
    return (
      <div>
        <Header />
        <BaseInput
          onBaseChange={this.onBaseChange}
          onRefresh={this.onRefresh}
          baseValue={this.state.baseValue}
          base={this.state.base}
          data={this.state.data}
        />
        <TableResult
          conversion={this.state.conversion}
          baseValue={this.state.baseValue}
          data={this.state.data}
        />
      </div>
    )
  }
}

export default App
