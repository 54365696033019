const getCurrencyData = () => [
  {
    name: "Indonesia Rupiah",
    country: "Indonesia",
    code: "IDR",
    flag: "https://flagcdn.com/id.svg",
  },
  {
    name: "US Dollar",
    country: "United States",
    code: "USD",
    flag: "https://flagcdn.com/us.svg",
  },
  {
    name: "Pound sterling",
    country: "Great Britain",
    code: "GBP",
    flag: "https://flagcdn.com/gb.svg",
  },
  {
    name: "Malaysia Ringgit",
    country: "Malaysia",
    code: "MYR",
    flag: "https://flagcdn.com/my.svg",
  },
  {
    name: "Euro",
    country: "Europe Union",
    code: "EUR",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg",
  },
  {
    name: "Japan Yen",
    country: "Japan",
    code: "JPY",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Flag_of_Japan.svg/1024px-Flag_of_Japan.svg.png",
  },
  {
    name: "Ruble",
    country: "Russia",
    code: "RUB",
    flag: "https://flagcdn.com/ru.svg",
  },
  {
    name: "Singapore Dollar",
    country: "Singapore",
    code: "SGD",
    flag: "https://flagcdn.com/sg.svg",
  },
  {
    name: "Australian Dollar",
    country: "Australia",
    code: "AUD",
    flag: "https://flagcdn.com/au.svg",
  },
  {
    name: "Baht",
    country: "Thailand",
    code: "THB",
    flag: "https://flagcdn.com/th.svg",
  },
  {
    name: "Hong Khong Dollar",
    country: "Honh Khong",
    code: "HKD",
    flag: "https://flagcdn.com/hk.svg",
  },
  {
    name: "Indian Rupee",
    country: "India",
    code: "INR",
    flag: "https://flagcdn.com/in.svg",
  },
  {
    name: "Bitcoin",
    country: "Bitcoin",
    code: "BTC",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1024px-Bitcoin.svg.png",
  },
]

export { getCurrencyData }
